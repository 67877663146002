<template>
  <div v-if="a_Documents">
    <v-progress-linear
        :active="b_GetDocsDialog"
        :indeterminate="b_GetDocsDialog"
        absolute
        bottom
        color="primary"
      />
      <v-row v-if="i_UploadFile == 1 && IsQSK">
      <v-col md="3">
        <v-btn 
          class="rounded-lg py-5" 
          tile block color="primary"
          @click="b_UploadDialog = true"
        >
          <v-icon left> mdi-file-plus </v-icon> {{ $t('general.add_file') }}
        </v-btn>
      </v-col>
    </v-row>
      
    <v-list>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="document of a_Documents"
          v-bind:key="document.i_DocumentID"
          @click="getDocument(document.i_DocumentID, document.s_Name)"
        >
          <v-list-item-icon>
            <v-icon color="danger">mdi-file-pdf-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text" v-text="document.s_Name" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-dialog
      v-model="b_GetDocDialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="white--text">
          {{$t('general.please_wait')}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="b_UploadDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
        <v-select
          :items="a_DocumentsLang"
          :label="$t('general.language')"
          v-model="i_FileLang"
          item-text="s_Name"
          item-value="i_ID"
          @change="$v.i_FileLang.$touch()"
          @blur="$v.i_FileLang.$touch()"
          :error-messages="FileLangErrors"
        ></v-select>
        <v-file-input
          accept=".pdf"
          v-model="o_File"
          :label="$t('general.choose_document')"
          @change="$v.o_File.$touch()"
          @blur="$v.o_File.$touch()"
          :error-messages="FileErrors"
        ></v-file-input>
        <br>
        <v-alert
          v-if="File_Size_TypeErrors && this.o_File"
          variant="outlined"
          type="warning"
          prominent
          border="top"
        >
        {{$t('errormessages.docSizeAndType')}}
        </v-alert>
        <v-btn
          color="primary"
          dark
          @click="sendDocument"
        >
          {{ $t('general.upload_document') }}
        </v-btn>
        </v-card-text>
        <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="b_UploadDialog = false, o_File = null"
        >
          {{$t('general.close')}}
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { get, getFile } from '../../worker/worker-api'
  import axios from "axios";
  import { mapGetters } from "vuex";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  export default {
    mixins: [validationMixin],
    name: 'DocumentList',
    props: ['i_DocumentType','i_UploadFile'],
    data: function () {
      return {
        b_GetDocDialog: false,
        b_GetDocsDialog: false,
        a_Documents: [],
        b_UploadDialog: false,
        i_FileLang:null,
        o_File:null,
        a_DocumentsLang:
        [
          {
            s_Name: "DE",
            i_ID: 1,
          },
          {
            s_Name: "FR",
            i_ID: 2,
          },
          {
            s_Name: "IT",
            i_ID: 3,
          }
        ],
        b_File_Size_TypeErrors:false
      }
    },
    validations: {
      o_File: {
        required
      },
      i_FileLang:{
        required
      }
    },
    computed: {
      ...mapGetters(["getRoles"]),
      IsQSK() {
        let result = this.getRoles.filter((Roles) => Roles.s_Role == "qsk");
        if (result.length > 0) {
          return true;
        }
        return false;
      },
      FileLangErrors() {
        const errors = [];
        if (!this.$v.i_FileLang.$dirty) return errors;
        !this.$v.i_FileLang.required &&
          errors.push(this.$t("errormessages.is_required"));
        return errors;
      },
      FileErrors() {
        const errors = [];
        if (!this.$v.o_File.$dirty) return errors;
        !this.$v.o_File.required &&
          errors.push(this.$t("errormessages.is_required"));
        return errors;
      },
      File_Size_TypeErrors(){
        if((this.o_File && this.o_File.size > 8388608) || (this.o_File && this.o_File.type != "application/pdf")){
          return true;
        }
        else{
          return false;
        }
      }
    },
    methods: {
      sendDocument(){
        this.$v.$touch();
        if((this.o_File && this.o_File.size > 8388608) || (this.o_File && this.o_File.type != "application/pdf")){
          this.b_File_Size_TypeErrors = true;
          return;
        }
        if (this.$v.$invalid) {
          return;
        }
        let formData = new FormData();
        formData.append("s_FileName", this.o_File.name);
        formData.append("i_FileLang", this.i_FileLang);
        formData.append("i_DocumentTypeID", this.i_DocumentType)
        formData.append("i_AddressRoleID", this.$smt.getAddressRoleID(this.$route.meta.role))
        formData.append("o_File", this.o_File, this.o_File.name);
        axios.post(`${process.env.VUE_APP_SMT_API_URL}/save/document`, formData,{
        })
          .then((response) => {
            this.getDocuments(this.i_DocumentType);
          })
          .catch((error) => {
            console.log("Worker error: ", error);
          });
      },
      getDocuments: function (Ai_DocumentType) {
        this.b_GetDocsDialog = true;
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/documents`, {
          i_DocumentTypeID: Ai_DocumentType,
          i_AddressRoleID: this.$smt.getAddressRoleID(this.$route.meta.role),
        })
          .then((response) => {
            this.a_Documents = response.Documents
            this.b_GetDocsDialog = false;
          })
          .catch((error) => {
            this.b_GetDocsDialog = false;
            console.log(error)
          })
      },
      getDocument (Ai_DocumenID, As_DocumentName) {
        this.b_GetDocDialog = true;
        getFile(`${process.env.VUE_APP_SMT_API_URL}/student/document/${Ai_DocumenID}`, {
          s_FileType: 'pdf',
        })
          .then(response => {
            let blob = new Blob([response], { type: 'application/pdf' });
            let url = URL.createObjectURL(blob);
            const a = document.createElement("a")
            a.style.display = "none"
            a.href = url
            a.download = As_DocumentName + '.pdf'
            a.target='_blank'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(a)
            //window.open(url);
            this.b_GetDocDialog = false;
          }).catch(error => {
            this.b_GetDocDialog = false;
            console.log(error)
          });
      }, // getDocument
    },
    created: function () {
      this.getDocuments(this.i_DocumentType);
    },
  }
</script>
